import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiRoutes } from "../../../constants/apiroutes";
import { Enums } from "../../../constants/enums";
import { APICall } from "../../../services/axiosService";
import { commonService } from "../../../services/common.service";
import { updateLoading } from "../../../store/actions";
import CreateWizardFour from "./CreateWizardFour";
import CreateWizardOne from "./CreateWizardOne";
import CreateWizardThree from "./CreateWizardThree";
import CreateWizardTwo from "./CreateWizardTwo";

const genderDropDownData = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

function Create() {

  const navigate = useNavigate();
  const location = useLocation();

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [serviceHoursList, setServiceHoursList] = useState([]);

  const [activeWizard, setActiveWizard] = useState(1);
  const authUserDtl = JSON.parse(commonService.getItem("user"));
  const [formInputs, setInput] = useState(
    {
      ...location?.state?.quotationDetails,
      branch_id: "",
    }
      ? {
          ...location.state.quotationDetails,
          // received_amount: location.state.quotationDetails.amount,
          total_amount: location.state.quotationDetails.amount,
          start_date: new Date(),
          timezone: "",
          guard_list: [],
          incharge_list: [],
          checkpoint_list: {},
          branch_id: "",
        }
      : null
  );
  const [checks, setChecks] = useState([]);
  const [checkJSON, setCheckJSON] = useState([]);

  const [formInputsErrors, setInputErrors] = useState({
    wizOne: {
      client_name: "",
      property_name: "",
      total_amount: "",
      // received_amount: "",
      no_of_guards: "",
      no_of_checkpoints: "",
      no_of_patrolling_in_day: "",
      start_date: "",
      timezone: "",
      service_hours_term: "",
      duration_term: "",
      // branch_list: "",
    },
    wizTwo: {
      guard_list: "",
    },
    wizThree: {
      incharge_list: "",
    },
    wizFour: {},
  });
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [guardsList, setGuardsList] = useState({
    isData: false,
    data: [],
  });

  const [dataInchargeTableLoading, setInchargeDataTableLoading] =
    useState(false);
  const [inchargePageNo, setInchargePageNo] = useState(1);
  const [inchargeTotalRows, setInchargeTotalRows] = useState(0);
  const [inchargePerPageRecords, setInchargePerPageRecords] = useState(10);
  const [inchargeList, setInchargeList] = useState({
    isData: false,
    data: [],
  });

  const [guardTypes, setGuardTypes] = useState({});
  const [selectedData, setSelectedData] = useState();

  const [timezoneList, setTimezoneList] = useState([]);
  const [selectedIncharges, setSelectedIncharges] = useState([]);
  let iniFetchGuardAssociation = {
    association_id: location.state.quotationDetails.company_id,
    association_type_term: Enums.UserRole.company,
  };
  const [fetchGuardAssociation, setFetchGuardAssociation] = useState(
    iniFetchGuardAssociation
  );

  const validation_msg = {
    client_name: "This field is required",
    property_name: "This field is required",
    total_amount: "This field is required",
    // received_amount: "This field is required",
    no_of_guards: "This field is required",
    no_of_checkpoints: "This field is required",
    no_of_patrolling_in_day: "This field is required",
    start_date: "This field is required",
    service_hours_term: "This field is required",
    duration_term: "This field is required",
    guard_list: "Please select guards",
    incharge_list: "Please select incharge",
    checkpoint: "This field is required",
    timezone: "Please select timezone",
    guard_name: "This field is required",
    guard_phone: "This field is required",
    guard_email: "This field is required",
    invalid_email: "Please enter valid email",
    id_number: "This field is required",
    gender: "This field is required",
    date_of_join: "This field is required",
    // branch_list: "This field is required",
    bank_name: "This field is required",
    account_no: "This field is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getGuardsList("", "", 1);
  }, [pageNo, perPageRecords]);

  useEffect(() => {
    getGuardsList("", "", 1);
    getInchargeList("", "", 1);
  }, [fetchGuardAssociation.association_id]);

  useEffect(() => {
    getInchargeList("", "", 1);
  }, [inchargePageNo, inchargePerPageRecords]);

  const getTimeZoneList = async () => {
    const response = await APICall(ApiRoutes.getTimezoneList);
    if (response.status == 1) {
      setTimezoneList(response.data.list);
      if (response.data.list.length) {
        setInput({
          ...formInputs,
          timezone: response.data.list[0].value,
        });
      }

      // setBranchData(response.data.list);
    }
  };

  useEffect(() => {
    getBranchData();
    getTimeZoneList();
    getQuotationDuration();
    getQuotationServiceHours();
  }, []);

  if (formInputs === null) {
    navigate("/");
    return;
  }

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  async function getGuardsList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setGuardsList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.GuardList, {
      is_all: false,
      page: pageNo,
      is_fixed_association: 1,
      ...fetchGuardAssociation,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date: filterStartDate,
        end_date: filterEndDate,
        status: 1,
        is_available: 1,
      },
    });

    if (requestMetadata) {
      setGuardsList({
        isData: true,
        data: requestMetadata.data.list,
      });

      let newGuardTypes = {};
      requestMetadata.data.list.forEach((itm) => {
        newGuardTypes = {
          ...newGuardTypes,
          [itm.guard_id]: Enums.GuardTypes.patrolling_guard,
        };
      });
      setGuardTypes(newGuardTypes);

      setGuardTotalRows(requestMetadata.data.total);
    }
    setDataTableLoading(false);
  }

  const handleSort = async (column, sortDirection) => {
    getGuardsList(column.sortField, sortDirection, 1);
  };

  const getQuotationDuration = async () => {
    const requestMetadata = await APICall(ApiRoutes.QuotationDuration);
    if (requestMetadata) {
      setDurationList(requestMetadata.data.list);
    }
  };

  const getQuotationServiceHours = async () => {
    const requestMetadata = await APICall(ApiRoutes.QuotationServiceHours);
    if (requestMetadata) {
      setServiceHoursList(requestMetadata.data.list);
    }
  };

  async function submitGuardsForm(event) {
    event.preventDefault();

    dispatch(updateLoading(true));
    setIsCompanyFormClick(true);
    dispatch(updateLoading(false));
    if (validateCompanyForm("wizFour")) {
      let newGuardList = [];

      formInputs.guard_list.forEach((itm) => {
        newGuardList = [
          ...newGuardList,
          {
            ...itm,
            guard_type: guardTypes[itm.guard_id],
          },
        ];
      });
      const requestMetadata = await APICall(ApiRoutes.ContractSave, {
        quotation_id: formInputs.quotation_id,
        quote_company_id: formInputs.quote_company_id,
        duration_term: formInputs.duration_term,
        service_hours_term: formInputs.service_hours_term,
        no_of_checkpoints: formInputs.no_of_checkpoints,
        no_of_patrolling_in_day: formInputs.no_of_patrolling_in_day,
        guard_list: newGuardList,
        incharge_list: formInputs.incharge_list,
        no_of_guards: formInputs.no_of_guards,
        total_amount: formInputs.total_amount,
        timezone: formInputs.timezone,
        branch_id: formInputs.branch_id,
        // received_amount: formInputs.received_amount,
        start_date: commonService.convertLocalToUTC(
          commonService.formatDate(formInputs.start_date) + " 00:00:00"
        ),
        checkpoint_list: Object.values(formInputs.checkpoint_list),
      });

      setIsCompanyFormClick(false);

      if (requestMetadata) {
        navigate("/contracts");
      }
    }
    setIsCompanyFormClick(false);
    dispatch(updateLoading(false));
  }

  function validateCompanyForm(wiz) {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors[wiz]);
    errorKeys.forEach((item) => {
      if (wiz === "wizFour") {
        if (
          formInputs.checkpoint_list[item] === null ||
          formInputs.checkpoint_list[item]?.toString().trim() === "" ||
          (Array.isArray(formInputs.checkpoint_list[item]) &&
            formInputs.checkpoint_list[item]?.length === 0)
        ) {
          errors[item] = validation_msg.checkpoint;
          isValid = false;
        } else {
          errors[item] = "";
        }
      } else {
        if (
          formInputs[item] === null ||
          formInputs[item]?.toString().trim() === "" ||
          (Array.isArray(formInputs[item]) && formInputs[item]?.length === 0)
        ) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (item === "guard_email" && !pattern.test(formInputs.guard_email)) {
            errors[item] = validation_msg.invalid_email;
            isValid = false;
          } else {
            errors[item] = "";
          }
          // if (wiz === "wizOne") {
          //   if (item == 'received_amount') {
          //     if (formInputs.received_amount > formInputs.total_amount) {
          //       errors[item] = "Received amount should not be greater than total amount.";
          //       isValid = false;
          //     }
          //   }
          //   else {
          //     errors[item] = "";
          //   }
          // }
        }
      }
    });

    setInputErrors((prevState) => ({ ...prevState, [wiz]: errors }));
    return isValid;
  }

  const inchargeHandlePageChange = (page) => {
    setInchargePageNo(page);
  };

  const inchargeHandlePerRowsChange = async (newPerPage, page) => {
    setInchargePerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getInchargeList("", "", 1);
  }

  async function getInchargeList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setInchargeDataTableLoading(true);
    setInchargeList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.InchargeList, {
      is_all: false,
      page: inchargePageNo,
      is_fixed_association: 1,
      ...fetchGuardAssociation,
      limit: inchargePerPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        status: 1,
        search_text: txtSearch,
        start_date: filterStartDate,
        end_date: filterEndDate,
      },
    });

    if (requestMetadata) {
      setInchargeList({ isData: true, data: requestMetadata.data.list });

      setInchargeTotalRows(requestMetadata.data.total);
    }
    setInchargeDataTableLoading(false);
  }

  const inchargeHandleSort = async (column, sortDirection) => {
    getInchargeList(column.sortField, sortDirection, 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.InchargeStatusUpdate, {
      id: item.incharge_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setInchargeList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const getBranchData = async () => {
    const response = await APICall(ApiRoutes.BranchDropDownList);
    if (response) {
      setBranchData(response.data.list);
    }
  };
  const onSelectedRowsChange = (state) => {
    setInput({
      ...formInputs,
      guard_list: state.selectedRows.map((item) => {
        return {
          guard_id: item.guard_id,
        };
      }),
    });
  };

  const onInchargeSelectedRowsChange = (state) => {
    setInput({
      ...formInputs,
      incharge_list: state.selectedRows.map((item) => item.incharge_id),
    });
  };

  const renderWizardScreen = () => {
    switch (activeWizard) {
      case 1:
        return (
          <CreateWizardOne
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizOne}
            genderDropDownData={genderDropDownData}
            branchData={branchData}
            fetchGuardAssociation={fetchGuardAssociation}
            setFetchGuardAssociation={setFetchGuardAssociation}
            iniFetchGuardAssociation={iniFetchGuardAssociation}
            durationList={durationList}
            timezoneList={timezoneList}
            serviceHoursList={serviceHoursList}
            setInput={(value) => setInput(value)}
            isCompany={
              authUserDtl.association_type_term == Enums.UserRole.company
            }
            onNextPress={() => {
              if (validateCompanyForm("wizOne")) {
                let obj = {};
                for (let i = 1; i <= formInputs.no_of_checkpoints; i++) {
                  obj = { ...obj, ["checkpoint" + i]: "" };
                  if (i == formInputs.no_of_checkpoints) {
                    setInput((prevState) => ({
                      ...prevState,
                      checkpoint_list: obj,
                    }));
                    setInputErrors((prevState) => ({
                      ...prevState,
                      wizFour: obj,
                    }));
                  }
                }

                setActiveWizard(2);
              }
            }}
          />
        );
      case 2:
        return (
          <CreateWizardTwo
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizTwo}
            genderDropDownData={genderDropDownData}
            guardsList={guardsList}
            setGuardsList={setGuardsList}
            guardTypes={guardTypes}
            setGuardTypes={setGuardTypes}
            dataTableLoading={dataTableLoading}
            companyTotalRows={companyTotalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            handleSort={handleSort}
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validateCompanyForm("wizTwo")) {
                setActiveWizard(3);
              }
            }}
            onSelectedRowsChange={onSelectedRowsChange}
            onPreviousPress={() => setActiveWizard(1)}
          />
        );
      case 3:
        return (
          <CreateWizardThree
            checkJSON={checkJSON}
            setCheckJSON={setCheckJSON}
            checks={checks}
            setChecks={setChecks}
            inchargePageNo={inchargePageNo}
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizThree}
            genderDropDownData={genderDropDownData}
            inchargeList={inchargeList}
            dataInchargeTableLoading={dataInchargeTableLoading}
            inchargeTotalRows={inchargeTotalRows}
            inchargeHandlePerRowsChange={inchargeHandlePerRowsChange}
            inchargeHandlePageChange={inchargeHandlePageChange}
            inchargeHandleSort={inchargeHandleSort}
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validateCompanyForm("wizThree")) {
                setActiveWizard(4);
              }
            }}
            onInchargeSelectedRowsChange={onInchargeSelectedRowsChange}
            onPreviousPress={() => setActiveWizard(2)}
          />
        );
      case 4:
        return (
          <CreateWizardFour
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizFour}
            genderDropDownData={genderDropDownData}
            setInput={(value) => setInput(value)}
            onNextPress={(event) => submitGuardsForm(event)}
            onPreviousPress={() => setActiveWizard(3)}
            isCompanyFormClick={isCompanyFormClick}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Create Contract
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/contracts">Setup</Breadcrumb.Item>
                <Breadcrumb.Item href="/contracts">Contract</Breadcrumb.Item>
                <Breadcrumb.Item active>Create</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="horizontal-wizard">
            <div className="bs-stepper linear">
              <div className="bs-stepper-header">
                <div className={`step ${activeWizard === 1 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">1</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Contract Details</span>
                      <span className="bs-stepper-subtitle">
                        Enter Contract Details
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 2 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">2</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Assign Guard</span>
                      <span className="bs-stepper-subtitle">
                        Assign Guard to Contract
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 3 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">3</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Assign Incharge</span>
                      <span className="bs-stepper-subtitle">
                        Assign Incharge to Contract
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 4 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">4</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Checkpoint list</span>
                      <span className="bs-stepper-subtitle">
                        Add Checkpoint list
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              {renderWizardScreen()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Create;
