import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import NewLoader from "../../../components/loader/NewLoader";
import { Enums } from "../../../constants";

export default function CreateWizardThree({
  checkJSON,
  setCheckJSON,
  checks,
  setChecks,
  inchargePageNo,
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  inchargeList,
  dataInchargeTableLoading,
  inchargeTotalRows,
  inchargeHandlePerRowsChange,
  inchargeHandlePageChange,
  inchargeHandleSort,
  onInchargeSelectedRowsChange,
}) {
  const [validated, setValidated] = useState(false);

  const handleSelectAllChange = (isChecked) => {
    const currentPageIds = inchargeList.data.map((row) => row.incharge_id);

    if (isChecked) {
      // Add all IDs from the current page to checks
      const updatedChecks = Array.from(new Set([...checks, ...currentPageIds]));
      setChecks(updatedChecks);
      setInput({ ...formInputs, incharge_list: updatedChecks });

      // Update checkJSON
      const updatedCheckJSON = [
        ...checkJSON.filter((item) => item.page !== inchargePageNo),
        { page: inchargePageNo, isAllCheck: true },
      ];
      setCheckJSON(updatedCheckJSON);
    } else {
      // Remove all IDs from the current page
      const updatedChecks = checks.filter((id) => !currentPageIds.includes(id));
      setChecks(updatedChecks);
      setInput({ ...formInputs, incharge_list: updatedChecks });

      // Update checkJSON
      const updatedCheckJSON = checkJSON.filter(
        (item) => item.page !== inchargePageNo
      );
      setCheckJSON(updatedCheckJSON);
    }
  };

  const handleIndividualCheckboxChange = (isChecked, inchargeId) => {
    if (isChecked) {
      const updatedChecks = [...checks, inchargeId];
      setChecks(updatedChecks);
      setInput({ ...formInputs, incharge_list: updatedChecks });
    } else {
      const updatedChecks = checks.filter((id) => id !== inchargeId);
      setChecks(updatedChecks);
      setInput({ ...formInputs, incharge_list: updatedChecks });

      // Uncheck global checkbox if necessary
      const currentPageIds = inchargeList.data.map((row) => row.incharge_id);
      if (updatedChecks.some((id) => currentPageIds.includes(id)) === false) {
        const updatedCheckJSON = checkJSON.map((item) =>
          item.page === inchargePageNo ? { ...item, isAllCheck: false } : item
        );
        setCheckJSON(updatedCheckJSON);
      }
    }
  };

  const isGlobalChecked = () => {
    return checkJSON.some(
      (item) => item.page === inchargePageNo && item.isAllCheck
    );
  };

  const companyTblColumns = [
    {
      name: (
        <div>
          <input
            type="checkbox"
            checked={isGlobalChecked()}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
        </div>
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={checks.includes(row.incharge_id)}
          onChange={(e) =>
            handleIndividualCheckboxChange(e.target.checked, row.incharge_id)
          }
        />
      ),
    },
    { name: "Email", selector: (row) => row.incharge_email, sortable: true },
    { name: "Phone", selector: (row) => row.incharge_phone, sortable: true },
    { name: "ID Number", selector: (row) => row.id_number, sortable: true },
    { name: "Gender", selector: (row) => row.gender, sortable: true },
  ];

  return (
    <div className="bs-stepper-content p-4">
      <Form validated={validated} onSubmit={(e) => e.preventDefault()}>
        <DataTable
          paginationDefaultPage={inchargePageNo}
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={companyTblColumns}
          data={inchargeList.data}
          progressPending={dataInchargeTableLoading}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          paginationTotalRows={inchargeTotalRows}
          onChangeRowsPerPage={inchargeHandlePerRowsChange}
          onChangePage={inchargeHandlePageChange}
          onSort={inchargeHandleSort}
        />
        {formInputsErrors?.incharge_list && (
          <Row>
            <span className="error">{formInputsErrors.incharge_list}</span>
          </Row>
        )}
        {/* <Row className="d-flex justify-content-between">
          <Button
            variant="outline-primary"
            className="btn-15"
            onClick={onPreviousPress}
          >
            <i className="fa-regular fa-arrow-left"></i> Previous
          </Button>
          <Button className="btn-15" variant="primary" onClick={onNextPress}>
            Next <i className="fa-regular fa-arrow-right"></i>
          </Button>
        </Row> */}

        <Row>
          <div className="d-flex justify-content-between">
            <Button
              type="submit"
              variant="outline-primary"
              className="btn-15"
              onClick={onPreviousPress}
            >
              <i className="fa-regular fa-arrow-left"></i>{" "}
              <span className="align-middle d-sm-inline-block d-none">
                Previous
              </span>
            </Button>
            <Button className="btn-15 " variant="primary" onClick={onNextPress}>
              <span className="align-middle d-sm-inline-block d-none">
                Next
              </span>{" "}
              <i className="fa-regular fa-arrow-right"></i>
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
}
